<template>
  <transition name="fade">
    <div v-if="show" class="preloader">
      <div class="loadani"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "preloader",
  props: ["show"],
};
</script>
