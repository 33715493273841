<template>
  <div id="shojo">
    <home />
  </div>
</template>

<script>
import home from "./pages/home.vue";

export default {
  name: "shojo",
  components: {
    home,
  },
};
</script>

<style>
body {
  margin: 0;
  background: #080808;
  position: absolute;
  width: 100vw;
}
.preloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 10000;
}
.imgload {
  display: none;
}
.preloader .loadani {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: rgba(22, 22, 22, 0.6);
  opacity: 0;
  animation: loading 1s 1s infinite alternate ease-in-out;
}
@keyframes loading {
  0% {
    opacity: 1;
    transform: translateX(-20px);
  }
  50% {
    opacity: 0.6;
  }
  100% {
    transform: translateX(20px);
    opacity: 1;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
