<template>
  <div class="wrapper">
    <preloader :show="isLoading" />
    <header>
      <div class="bg-rep" />
      <img :src="require('../img/bg-top.jpg')" class="bg-top" />
      <img :src="require('../img/bg-top-m.jpg')" class="bg-top-m" />
      <img :src="require('../img/f-blu.png')" class="f-blu" />
      <img :src="require('../img/f-red.png')" class="f-red" />
      <img :src="require('../img/bf-yel.png')" class="bf-yel" />
      <img :src="require('../img/bf-red.png')" class="bf-red" />
      <img :src="require('../img/l-han.svg')" class="l-han" />
      <div class="logo" />

      <div class="title-audio-box">
        <div class="layout">
          <div class="description">
            <h1 class="d-title">あの人にハレを届ける</h1>
            <h2 class="d-description">Spread the celebration.</h2>
          </div>
          <div class="music">
            <audio controls class="music-con">
              <source src="/music/猩猩～徳.mp3" type="audio/mpeg" />
              <p>
                Your browser doesn't support HTML5 audio. Luckily for you, we've
                included a <a href="../music/猩猩～徳.mp3">link to our song</a>.
              </p>
            </audio>
          </div>
        </div>
        <div class="spirals-box">
          <img :src="require('../img/spirals.svg')" class="spirals" />
        </div>
        <div class="down-box">
          <a href="#section_01">
            <img :src="require('../img/down.svg')" class="down" />
          </a>
        </div>
      </div>
    </header>
    <section id="section_01" class="content">
      <div class="title">
        <img class="yeti" :src="require('../img/yeti.svg')" />
      </div>
    </section>

    <section class="content-text">
      <div class="con-text">
        <p>
          この度は蓄音酒〜レコ酒〜を<br />
          お手に取っていただきまして<br />
          &nbsp;誠にありがとうございました。
        </p>

        <p>楽しんでいただけましたでしょうか？</p>

        <p>
          レコ酒は皆さまの日常に<br />
          "ハレ„をお届けしたい、<br />
          五感で楽しんでいただきたい
        </p>

        <p>
          このような思いをもって<br />
          &nbsp;四国の伝統が手を取り合い完成しました。
        </p>

        <p>
          皆さまの日常が<br />
          もっともっとハレますように！
        </p>

        <p>
          そして次は皆さまも、<br />
          あの人にハレを届けませんか？
        </p>
      </div>
    </section>

    <section class="shop">
      <!--
      <div class="buy-now">
        <div class="cup">
          <img class="cup-img" src="../img/shop-cup.jpg" />
          <p>カップ酒セット<br/>180㎖×３</p>
        </div>
        <div class="bottle">
          <img class="bottle-img" src="../img/shop-bottle.jpg" />
          <p>４合瓶<br/>720㎖</p>          
        </div>
      </div>
      -->
      <div class="cart">
        <a target="_blank" href="https://shop.sho-jo.com/" style=""
          ><img class="buy-button" src="../img/cvlink.jpg"
        /></a>
      </div>
    </section>
  </div>
</template>

<script>
import preloader from '../components/preloader.vue';
export default {
  components: { preloader },
  name: 'home',
  created() {
    const images = this.imagesToPreload.map((imagSrc) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imagSrc;
        img.onload = resolve;
        img.onerror = reject;
      });
    });
    Promise.all(images)
      .then(() => {
        console.log('Images loaded!');
        this.isLoading = false;
      })
      .catch((error) => {
        console.error('Some image(s) failed loading!');
        console.error(error.message);
      });
  },
  data() {
    return {
      isLoading: true,
      imagesToPreload: [
        require('../img/spirals.svg'),
        require('../img/bg-top.jpg'),
        require('../img/bg-top-m.jpg'),
        require('../img/f-blu.png'),
        require('../img/f-red.png'),
        require('../img/bf-yel.png'),
        require('../img/bf-red.png'),
        require('../img/l-han.svg'),
        require('../img/m-sho_rounded.svg'),
        require('../img/yeti.svg'),
        require('../img/bg-rep.jpg'),
        require('../img/cvlink.jpg'),
      ],
    };
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'Yu Mincho';
  src: url('../fonts/yumin.ttf'); 
}
@font-face {
  font-family: 'Apple Chancery';
  src: url('../fonts/apple-chancery.ttf');
}

html {
  scroll-behavior: smooth; /* for the down button */
}
.wrapper {
  width: auto;
  height: 100vh;
  overflow-x: hidden;
}
header {
  position: relative;
  display: flex;
  justify-content: center;
  height: 80vw;

  background: #080a0b;
  background: linear-gradient(
    180deg,
    rgba(8, 10, 11, 1) 0%,
    rgba(9, 9, 11, 1) 80%,
    rgba(255, 255, 255, 1) 100%
  );
}
.bg-top {
  position: absolute;
  width: 100vw;

  height: auto;
  animation: fadein ease 3s;
  /* transform: translateZ(-28px) translateY(6vh) scale(3.8); */
}
.bg-top-m {
  visibility: hidden;
}
.f-blu {
  position: absolute;
  max-width: 680px;
  max-height: 680px;
  width: 40vw;
  height: auto;
  left: 4vw;
  top: -2vw;
  animation: fadein ease 3.6s;
  transform: rotate(14deg);
  /* transform: translateX(-60vw) translateY(50vw); /* translateZ(-15px) scale(2.5) */
}
.f-red {
  position: absolute;
  max-width: 680px;
  max-height: 680px;
  width: 40vw;
  height: auto;
  right: 3vw;
  top: -1vw;
  animation: fadein ease 4s;
  /* transform: translateX(60vw) translateY(20vw); /* translateZ(-10px) scale(2) */
}
.bf-yel {
  position: absolute;
  max-width: 300px;
  max-height: 300px;
  width: 14vw;
  height: auto;
  left: 32vw;
  top: 28vw;
  animation: fadein ease 4.5s;
  transform: rotate(25deg);
  /* transform: translateX(-20vw) translateY(112vw); /*  translateZ(-6px) scale(1.6) */
}
.bf-red {
  position: absolute;
  max-width: 300px;
  max-height: 300px;
  width: 15vw;
  height: auto;
  right: 28vw;
  top: 31vw;
  animation: fadein ease 4.7s;
  transform: rotate(10deg);
  /* transform: translateX(25vw) translateY(83vw); /*  translateZ(-3px) scale(1.3) */
}
.spirals-box {
  padding-top: 2vh;
}
.spirals {
  position: absolute;
  visibility: hidden;
  animation: fadein ease 10s;
  /* transform: translateY(200vw); /*  translateZ(-40px) scale(3) */
}
.l-han {
  position: absolute;
  top: 15vw;
  width: 14vw;
  text-align: center;
  height: auto;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.6));
  animation: fadein ease 6s;
}
@keyframes downbob {
  0% {
    opacity: 0.1;
  }
  100% {
    transform: translateY(5px);
    opacity: 0.8;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.title-audio-box {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  width: 96vw;
  top: 33vw;
}
.layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.description {
  display: flex;
  flex-direction: column;
  animation: fadein ease 8s;
  z-index: 1;
  padding-left: 4vw;
}
.d-title {
  font-size: 2vw;
  font-family: 'Yu Mincho', sans-serif;
  color: #eee;
  text-shadow: black 0 0 5px;
  margin: 0;
}
.d-description {
  font-size: 2vw;
  font-family: 'Apple Chancery';
  color: #eee;
  text-shadow: black 0 0 5px;
  margin: 0;
  padding-left: 1em;
}
.music {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-right: 1vw;
  color: #eee;
  text-shadow: black 0 0 5px;
  animation: fadein ease 6.8s;
  height: 100%;
}
.music-con {
  width: 25vw;
}
.down-box {
  display: flex;
  justify-content: center;
}
.down {
  width: 5vmin;
  height: auto;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.6));
  animation: downbob 1s 7s infinite alternate ease-in-out;
  opacity: 0;
}
.content {
  /*  background: radial-gradient(
    ellipse at top,
    rgba(255, 255, 255, 0.99),
    rgba(206, 206, 206, 0.99)
  );
  outline: 20px solid rgba(218, 218, 218, 0.5);
  border-top: 6px solid rgba(228, 228, 228, 0.5); */
  background: #fff;
  margin-bottom: -2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Zen Kurenaido', sans-serif;
  font-weight: 400;
  font-size: 1.5em;
  padding: 2em;
  color: rgb(24, 24, 24);
  margin-top: -1px;
}
.yeti {
  width: 500px;
}
.content-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-family: 'Yu Mincho';
  font-weight: 600;
  font-size: 2rem;
  padding: 0.5em 2em;
}
.con-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1024px;
}
.shop {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.buy-button {
  margin-top: 2em;
  margin-bottom: 8em;
  text-align: center;
  box-shadow: #454b50 5px 5px 10px;
  transition: box-shadow 0.5s;
  width: 50vw;
  max-width: 600px;
}
.buy-button:hover {
  box-shadow: pink 0px 0px 40px;
}
.cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1000px) {
  /* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= 1200px */
  /*  .spirals {
    width: 50vw;
  }
  .l-han {
    left: unset;
  }
  .f-blu {
    width: 50vmin;
    right: 50vw;
    top: 20vh;
  }
  .f-red {
    width: 50vmin;
    left: 50vw;
    top: 26vh;
  }
  .bf-yel {
    width: 20vmin;
    right: 48vw;
    top: 60vh;
  }
  .bf-red {
    width: 20vmin;
    left: 49vw;
    top: 68vh;
  }
  .d-title {
    font-size: 24px;
  }
  .d-description {
    font-size: 20px;
  }
  .music {
    padding-bottom: 5px;
  }
  .music-con {
    padding-bottom: 15px;
  }
  .layout {
    top: 70vh;
  }*/
}
@media (max-width: 700px) {
  /* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= 900px */
  header {
    height: 200vw;
    min-height: unset;
    background: fff;
    background: linear-gradient(
      180deg,
      rgba(8, 10, 11, 1) 0%,
      rgba(9, 9, 11, 1) 87%,
      rgba(255, 255, 255, 1) 98%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  .bg-top {
    visibility: hidden;
  }
  .bg-top-m {
    visibility: visible;
    width: 120vw;
    height: 120vw;
  }
  .f-blu {
    width: 52vmin;
    left: -2vw;
    top: 10vh;
    /* transform: translateX(-60vw) translateY(50vw); /* translateZ(-15px) scale(2.5) */
  }
  .f-red {
    width: 52vmin;
    right: -6vw;
    top: 10vh;
    /* transform: translateX(60vw) translateY(20vw); /* translateZ(-10px) scale(2) */
  }
  .bf-yel {
    width: 22vmin;
    left: 26vw;
    top: 68vw;
    /* transform: translateX(-20vw) translateY(112vw); /*  translateZ(-6px) scale(1.6) */
  }
  .bf-red {
    width: 22vmin;
    right: 24vw;
    top: 74vw;
    /* transform: translateX(25vw) translateY(83vw); /*  translateZ(-3px) scale(1.3) */
  }
  .spirals-box {
    display: flex;
    justify-content: center;
    padding-top: 2vh;
  }
  .spirals {
    visibility: visible;
    width: 80vw;
    /* transform: translateY(200vw); /*  translateZ(-40px) scale(3) */
  }
  .l-han {
    top: 3vh;
    width: 24vmin;
  }
  .title-audio-box {
    top: 98vw;
  }
  .layout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .description {
    width: 100%;
    height: auto;
    align-items: center;
    position: relative;
    top: unset;
    left: unset;
    padding: unset;
  }
  .d-title {
    position: relative;
    font-size: 28px;
    text-shadow: black 0 0 5px;
    text-align: center;
    margin-top: 0.4em;
    margin-bottom: 0.4em;
    line-height: 1.2;
    padding: unset;
  }
  .d-description {
    position: relative;
    font-size: 22px;
    text-shadow: black 0 0 5px;
    padding: unset;
  }
  .music {
    padding-top: 8px;
    padding-right: unset;
    top: unset;
    right: unset;
    width: unset;
    position: relative;
    font-size: 3.1vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-shadow: black 0 0 5px;
  }
  .music-con {
    position: relative;
    width: 80vw;
    height: 40px;
    padding: unset;
    margin: unset;
    padding-top: 10px;
  }
  .down {
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
    padding-top: 10px;
    width: 6vw;
    height: auto;
  }
  @keyframes downbob {
    0% {
      opacity: 0.1;
    }
    100% {
      transform: translateY(5px);
      opacity: 0.8;
    }
  }
  .yeti {
    width: 50vw;
    max-width: 300px;
  }
  .stuff {
    font-size: 1em;
  }
  .content-text {
    font-size: calc(18px + 6 * ((100vw - 320px) / 680));
    padding: unset;
  }
  .buy-button {
    width: 80vw;
    max-width: 300px;
  }
}
/*
.logo {
  position: absolute;
  mask: url(../img/l-han.svg);
  top: 30vh;
  width: 50vmin;
  height: 50vmin;
  background: linear-gradient(rgb(255, 182, 46), 
                              rgb(51, 255, 153),
                              rgb(48, 162, 255),
                              rgb(194, 66, 253));
  background-size: 400% 400%;
  opacity: .8;
  animation: gradient 10s ease-in-out alternate infinite;
}
@keyframes gradient {
    0% { background-position: top center; }
    25% { background-position: top 25% center; }
    50% { background-position: top 50% center; }
    75% { background-position: top 75% center; }
    100% { background-position: top 100% center; }
}
*/
</style>
